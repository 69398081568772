import Typography from "@material-ui/core/Typography";
import * as React from "react";

import styled from "~/components/core/styled";
import { isString } from "~/utils/common";

interface Props {
  caption?: React.ReactNode;
}

const LoadingIndicatorCaption: React.FC<Props> = ({
  caption
}: Props): JSX.Element | null => {
  if (isString(caption)) {
    return <StyledTypography variant="h5">{caption}</StyledTypography>;
  }

  if (React.isValidElement(caption)) {
    return caption;
  }

  return null;
};

export default LoadingIndicatorCaption;

const StyledTypography = styled(Typography)`
  font-weight: 900;
`;
