import CircularProgress from "@material-ui/core/CircularProgress";
import * as React from "react";

import styled from "~/components/core/styled";
import { hexToRgba } from "~/utils/style";

import LoadingIndicatorCaption from "./LoadingIndicatorCaption";
import { useInViewportOffset } from "./useInViewportOffset";

interface Props {
  caption?: React.ReactNode;
  className?: string;
  indicatorInViewport?: boolean;
}

const LoadingIndicator: React.FC<Props> = ({
  className,
  caption,
  indicatorInViewport
}: Props): JSX.Element => {
  const loaderRef = useInViewportOffset(indicatorInViewport);

  return (
    <IndicatorSubstrate
      ref={loaderRef}
      aria-busy="true"
      className={className}
      indicatorInViewport={indicatorInViewport}
    >
      <LoadingIndicatorCaption caption={caption} />
      <StyledCircularProgress color="primary" />
    </IndicatorSubstrate>
  );
};

export default LoadingIndicator;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }): string => theme.palette.data.turquoise[900]};
  margin-top: 15px;
`;

const IndicatorSubstrate = styled.div<{ indicatorInViewport?: boolean }>`
  align-items: center;
  background-color: ${({ theme }): string =>
    hexToRgba(theme.palette.primary.light, 0.5)};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({ indicatorInViewport }): string =>
    indicatorInViewport ? "default" : "center"};
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
