import Link from "@material-ui/core/Link";
import { default as NextLink } from "next/link";
import * as React from "react";

import { AuthFormActionButton } from "~/components/auth/styled";
import styled from "~/components/core/styled";

interface Props {
  mainAction: {
    caption: string;
    disabled: boolean;
  };
  secondaryAction?: {
    caption: string;
    href: string;
  };
}

const AuthFormActions: React.FC<Props> = ({
  mainAction,
  secondaryAction
}: Props): JSX.Element => (
  <ActionsWrapper>
    <AuthFormActionButton
      variant="contained"
      color="primary"
      type="submit"
      disabled={mainAction.disabled}
    >
      {mainAction.caption}
    </AuthFormActionButton>

    {secondaryAction && (
      <NextLink href={secondaryAction.href}>
        <ForgotPasswordLink>{secondaryAction.caption}</ForgotPasswordLink>
      </NextLink>
    )}
  </ActionsWrapper>
);

export default AuthFormActions;

const ActionsWrapper = styled.footer`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const ForgotPasswordLink = styled(Link)`
  font-size: 14px;
`;
