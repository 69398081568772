const ROUTES = {
  home: "/",
  lessons: "/lessons",
  writingLabs: "/writing-labs",
  students: "/students",
  reports: "/reports",
  trending: "/trending",
  settings: "/settings",
  auth: {
    signup: "/signup",
    passwordReset: "/password-reset"
  }
};

export default ROUTES;
