import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { SnackbarProvider } from "notistack";
import * as React from "react";

import LogoFull from "~/assets/images/Logo_PearDeckTutor_white.svg?sprite";
import styled from "~/components/core/styled";

import LayoutHead from "../common/LayoutHead";

interface Props {
  children: React.ReactElement | React.ReactElement[] | string;
}

const AuthLayout: React.FC<Props> = ({ children }: Props): JSX.Element => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
  >
    <LayoutHead />
    <Wrapper>
      <Header>
        <Logo />
        <Subtitle>Formerly TutorMe</Subtitle>
      </Header>
      <Content>{children}</Content>
    </Wrapper>
  </SnackbarProvider>
);

export default AuthLayout;

const Logo = styled(LogoFull)`
  height: 52px;
  width: 317px;
`;

const Subtitle = styled(Typography)`
  margin-left: 44px;
  margin-top: -12px;
`;

const Wrapper = styled.main`
  align-items: center;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }): string => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;

const Header = styled.header`
  margin-bottom: 20px;
`;

const Content = styled(Paper)`
  margin: 20px;
  min-height: 400px;
  overflow: hidden;
  padding: 45px;
  position: relative;
  width: 535px;
`;
