import { Button, Link } from "@material-ui/core";

import styled from "~/components/core/styled";

export const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const AuthFormHeader = styled.header`
  height: 30px;
`;

export const AuthFormCaption = styled.h3`
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 20px;
`;

export const AuthFormFieldsWrapper = styled.div`
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  justify-content: space-between;
`;

export const AuthFormActionButton = styled(Button)`
  color: ${({ theme }): string => theme.palette.common.white};
  min-width: 165px;
`;

export const AuthFormActions = styled.footer`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const AuthFormActionLink = styled(Link)`
  font-size: 14px;
`;

export const AuthFormStatusWrapper = styled.div`
  color: ${({ theme }): string => theme.palette.error.main};
  height: 15px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
`;
